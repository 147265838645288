<template>
  <div class="videoBox" @click="showControl">
    {{ playStatus }}
    <!-- 播放暂停浮层 -->
    <div class="play-pause-control" :class="showCon ? '' : 'show'">
      <img
        v-if="playStatus == 1"
        src="@/assets/img/player/play.svg"
        alt=""
        @click="pause"
      />
      <img v-else src="@/assets/img/player/pause.svg" alt="" @click.stop="play" />
    </div>
    <video
    class="videoStyle"
      :style="{height: widnowHeight + 'px'}"
      muted
      autoplay
      webkit-playsinline
      playsinline
    >
      <source :src="src" type="video/mp4" />
      浏览器太旧，请更换浏览器
    </video>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ""
    },
    widnowHeight:{
      type:Number,
      default:0
    }
  },
  data() {
    return {
      videoPlay: null,
      showCon:true,
      playStatus: 0, // 播放状态 0 播放 1 暂停
    };
  },
  created() {
    // 微信浏览器判断
    const ua = navigator.userAgent.toLowerCase();
    // 如果是微信环境，不会自动播放（微信策略限制）
    this.playStatus = ua.match(/MicroMessenger/i) == "micromessenger" ? 1 : 0;
  },
  mounted() {
    const video = document.querySelector("video");
    this.videoPlay = video;

    video.addEventListener("play", () => {
      console.log("视频播放");
      this.playStatus = 0;
      setTimeout(() => {
        this.showCon = false
      }, 1000)
      
    });

    // 当视频暂停时
    video.addEventListener("pause", () => {
      console.log("视频暂停");
      this.showCon = true
      this.playStatus = 1;
      
    });

    // 当视频播放结束时
    video.addEventListener("ended", () => {
      console.log("播放结束");
      this.playStatus = 1;
      this.showCon = true
    });
  },
  methods: {
    showControl(){
      this.showCon = !this.showCon
    },
    play() {
      this.videoPlay.pause();
    },
    pause() {
      this.videoPlay.play();
    }
  }
};
</script>

<style lang="less" scoped>
.videoBox {
  position: relative;
  .play-pause-control {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.55);
    border-radius: 50%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
     opacity: 1;
     transition: all 0.5s;
    img {
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  .show{
    opacity: 0;
  }

  .videoStyle{
    width: 100%; 
    object-fit: cover;
  }
}
</style>
