<template>
    <div class="details-box">
        <img src="@/assets/img/home.png" alt="" class="homePng" @click="$router.push({path:'/'})" v-if="$route.query.from=='pageShare'">
        <!-- <VideoPlayer :src="src"/> -->
        <!--  顶部轮播 -->
        <template v-if="infoData.nickName != ''">
            <van-swipe class="my-swipe" :autoplay="autoplay" v-if="images.length>0" indicator-color="white" :lazy-render="true"
                @change="onChange" ref="swipe">
                <van-swipe-item v-for="(item, index) in images" :key="index" :style="{height: widnowHeight + 'px'}">
                    <template v-if="item.fileType == 0">
                        <van-image :src="item.fileUrl" lazy-load>
                            <template v-slot:loading>
                                <van-loading type="spinner" size="20" />
                            </template>
                        </van-image>
                    </template>
                    <template v-if="item.fileType != 0 && item.fileUrl">
                        <VideoPlayer :src="item.fileUrl"  :widnowHeight="widnowHeight"/>
                    </template>
                </van-swipe-item>
                <!-- 自定义指示器 -->
                <template #indicator>
                    <div class="custom-indicator">{{ current + 1 }}/{{ images.length }}</div>
                </template>
            </van-swipe>

            <!-- 内容信息 -->
            <div class="content">
                <!-- 用户信息 -->
                <div class="userInfo">
                    <!-- 用户内容 -->
                    <div class="user">
                        <div class="user-expos">
                            <div class="name-follow">
                                <span class="name">{{ infoData.nickName }}</span>
                                <template v-if="infoData.isCollect == 0">
                                    <span class="follow" @click="foll">关注</span>
                                </template>
                                <template v-else>
                                    <span class="cancelFollow" @click="cFoll">已关注</span>
                                </template>
                            </div>
                            <div class="info">
                                <p class="rate-p"><van-rate v-model="infoData.star" size="15" readonly allow-half
                                        color="orange" />
                                    {{ infoData.star }}</p>
                                <p class="plane">
                                    <img src="@/assets/img/attendant/plane.svg">
                                    {{ infoData.distance }} km
                                </p>
                                <p class="follow_number">
                                    关注度 {{ infoData.repeatCustomer>99?'99+':infoData.repeatCustomer }}
                                </p>
                            </div>
                        </div>
                        <div class="time-show-div">
                            <p class="time-show" v-if="infoData.isOutOfRange != 1">
                                <span>最早可约</span>
                                <span>{{ getToDay(infoData.earliestAppointmentDate) }}
                                    {{ infoData.earliestAppointmentTime }}</span>
                            </p>
                            <p class="time-show" v-else>
                                <span>超出范围</span>
                            </p>
                        </div>
                    </div>
                    <!-- 黄色公告栏 -->
                    <div class="notice_lan" @click="showDialogToast">
                        <li>
                            <img src="@/assets/img/attendant/phone.svg" alt="">
                            手机认证
                        </li>
                        <li>
                            <img src="@/assets/img/attendant/identify.svg" alt="">
                            实名认证
                        </li>
                        <li>
                            <img src="@/assets/img/attendant/identify_2.svg" alt="">
                            按摩证
                        </li>
                        <li>
                            <img src="@/assets/img/attendant/healthy.svg" alt="">
                            健康证
                        </li>
                    </div>
                    <!-- 其他基础信息 -->
                    <div class="base_info">
                        <div class="base_box">
                            <div class="item" v-for="(item, index) in infoData.labels" :key="index">
                                <span class="label">{{ item.labelName }}：</span>
                                <span class="text">{{ item.labelValue }}</span>
                            </div>
                        </div>
                        <p class="desc">
                            {{ infoData.introduction }}
                        </p>
                    </div>
                </div>
                <!-- 占位符灰色 -->
                <div class="grey-line"></div>
                <!--  服务项目 -->
                <div class="project">
                    <p class="div-title">
                        <span class="title">服务项目</span>
                    </p>
                    <!-- <ProjectList :serveList="infoData.projectList"/> -->
                    <div class="project-div" v-if="infoData.projectList.length > 0">
                        <div v-for="(item, index) in infoData.projectList" :key="index" class="projectList"
                            @click.stop="$router.push({ path: '/projectDetails', query: { id: item.id } })">
                            <img class="projectImg" :src="item.img" alt="">
                            <div class="information">
                                <p class="name">{{ item.name }}</p>
                                <p class="vip-information">
                                    <span class="money">
                                        <font class="moneyIcon">￥</font>{{ item.price }}
                                    </span>
                                    <span class="vipSpan"><img src="@/assets/img/vip_icon.png" alt=""
                                            class="vipIcon"><span>￥{{
                                                item.vipPrice }}</span></span>
                                </p>
                                <p class="vip-information"><van-icon name="clock-o" class="timeIcon" /><span>{{
                                    item.serveTime
                                        }}分钟</span></p>
                                <div class="synopsis-operation">
                                    <p class="synopsis">{{ item.description }}</p>
                                    <div class="operation" >
                                        <van-button v-if="infoData.isOutOfRange != 1" type="primary" size="mini" class="operationBtn" color="#E82B2F" round @click.stop="placeOrder(item)">预约</van-button>
                                        <van-button v-else size="mini" class="operationBtn" disabled color="#ccc" round>超出范围</van-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <template v-else>
                         <p style="text-align: center;width: 100%;color: #ccc;padding: .2rem 0;">-- 暂无数据 --</p>
                    </template>
                </div>
                <!-- 占位符灰色 -->
                <div class="grey-line"></div>

                <div class="commentBox">
                    <p class="div-title">
                        <span class="title">评价（{{commentTotal}}）</span>
                    </p>
                    <template v-if="commentList.length > 0">
                        <div class="comment_item" v-for="(item, index) in commentList" :key="index" >
                            <div class="info_box">
                                <div class="user_info">
                                    <img  class="avatar" :src="item.formUidAvatar" alt="" v-if="item.formUidAvatar">
                                    <img  class="avatar" src="@/assets/img/df_avator.png" alt="" v-else>
                                    <!-- <div class="avatar" :style="{ backgroundImage: 'url(' + item.artificerAvatar + ')' }"></div> -->
                                    <div class="info">
                                        <div class="name">
                                            <span class="text">{{ item.formUidName || '匿名' }}</span>
                                        </div>
                                        <div class="star">
                                            <van-rate v-model="item.star" size="15" readonly allow-half color="orange" />
                                        </div>
                                    </div>
                                </div>
                                <div class="time">{{ item.createTime }}</div>
                            </div>
                            <div class="comment">
                                {{ item.content }}
                            </div>
                        </div>
                    </template>
                     <template v-else>
                         <p style="text-align: center;width: 100%;color: #ccc;padding: .2rem 0;">-- 暂无数据 --</p>
                    </template>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="loading-box">
                <van-loading />
                <p class="text">加载中...</p>
            </div>
        </template>
    </div>
</template>

<script>
import {follow, noCollectionArtificer} from '@/api/my'
import { getArtificerDetail, getArtificerDetailComment } from '@/api/service'
import VideoPlayer from '@/components/videoPlayer.vue'
import { getToDay, getweekday, throttle } from "@/utils/index"
import AMapLoader from '@amap/amap-jsapi-loader';
import Location from '@/utils/location'
import { wxConfig } from '../../utils/index';
export default {
    data() {
        return {
            lng: 116.397455,
            lat: 39.919087,
            src: 'https://lhdj.obs.cn-north-4.myhuaweicloud.com:443/artificer%2F20220808%2F1659926994.287054_36B75637-0AB5-4668-8489-D74DC3885913.MP4',
            avatar: "https://q1.itc.cn/q_70/images03/20240612/386106412a6a47e797ba661b7001c244.jpeg",
            current: 0,
            images: [
                'https://img1.baidu.com/it/u=4214113141,2036563917&fm=253&app=120&size=w931&n=0&f=JPEG&fmt=auto?sec=1719507600&t=1d19d1950a99108351d6462dd5091ed5',
            ],
            autoplay: 3000,                  // 自动播放间隔，单位为毫秒
            star: 4.5,
            videoOpen: false,
            videoUrl: "",
            infoData: {
                "nickName": "",
                "repeatCustomer": 0,
                "star": 0,
                "distance": 0,
                "introduction": "",
                "longitude": 116.397455,
                "latitude": 39.919087,
                "earliestAppointmentDate": "",
                "earliestAppointmentTime": "",
                "isCollect": 0,
                "labels": [],
                "lsfbArtificerFileEntityList": [],
                "projectList": []
            },
            page:1,
            commentTotal:0,
            commentList: [],
            isLoad:true,
            throttledCheckScroll:null,              // 存储节流函数实例
        }
    },
    components: {
        VideoPlayer
    },
    created() {
        this.artificerId = this.$route.query.id
        // this.setMap()
        this.getLatlng()
        //  获取当前页面宽度
        this.widnowHeight = window.screen.width
    },
    mounted() {
        //    监听页面滚动到底部,
        this.throttledCheckScroll = throttle(this.handleScroll, 200); 
        window.addEventListener('scroll', this.throttledCheckScroll)
    },
    destroyed() {
        window.removeEventListener('scroll', this.throttledCheckScroll)
    },
    methods: {
         setMap() {
            let _this = this
            AMapLoader.load({
                plugins: ['AMap.Geolocation'],
            }).then((AMap) => {
                Location.getCurrentLocation(AMap, (result) => {
                    _this.mapAddress = result.pois[0].name || result.addressComponent.building
                    _this.lng = result.position.lng
                    _this.lat = result.position.lat
                    this.getData()
                    this.getComment()
                }, () => {
                    this.$toast("定位失败，请检查网络或GPS是否正常开启")
                    this.getData()
                    this.getComment()
                })
            }).catch(e => {
                console.log(e);
            });
        },
        getLatlng(){
            const selectCityAddress = JSON.parse(sessionStorage.getItem('selectCityAddress'))
            if (selectCityAddress) {
                    // sessionStorage.removeItem('selectCityAddress')
                this.mapAddress = selectCityAddress.cityInfo.detailAddress 
                this.lat = selectCityAddress.cityInfo.lat
                this.lng = selectCityAddress.cityInfo.lng
                this.getData()
                this.getComment()
            }else{
            this.setMap();
            }
        },
        handleScroll() {  
            const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;  
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;  
            const clientHeight = window.innerHeight || document.documentElement.clientHeight;  
        
            // 判断是否滚动到底部  
            if (scrollTop + clientHeight >= scrollHeight - 100 && this.isLoad) { // 减去100是为了在距离底部还有一点距离时就触发  
                console.log('滚动到底部了');  
                // 这里可以添加你的逻辑，比如加载更多数据 
                this.getComment()
            }  
        },  
        getToDay(str) {
            return getToDay(str)
        },
        // 下单
        placeOrder(val){
            let data = {
                type :2,
                serve_name:this.infoData.nickName,
                serve_id:this.infoData.id,
                serve_url:this.infoData.artificerAvatar,
                appointment_time:this.infoData.earliestAppointmentTime,
                id:val.id,
                appointment_time_title: getToDay(this.infoData.earliestAppointmentDate)+'天'+getweekday(this.infoData.earliestAppointmentDate),
                timeActive:this.infoData.earliestAppointmentDate,
            }
            if(this.getwxcode('/order/confirmOrder',data)){
                this.$router.push({
                    path:"/order/confirmOrder",
                    query:data
                })
            }
        },
        showDialogToast() {
            this.$dialog.alert({
                title: '提示',
                message: '为您服务时向您展示资质证件~',
                confirmButtonText: "我知道了",
                confirmButtonColor: "#333"
            }).then(() => {
                // on close
            });
        },
        foll(){
            if(this.getwxcode('/attendantDetails',{id:this.$route.query.id})){
                follow({
                    "artificerId": this.artificerId
                }).then(res => {
                    if (res.code == 10000) {
                        this.infoData.isCollect = 1
                        this.$toast('关注成功~')
                    }
                }).catch(err => {
                    console.log(err)
                })
            }
        },
        cFoll(){
            noCollectionArtificer({
                "artificerId": this.artificerId
            }).then(res => {
                if (res.code == 10000) {
                    this.infoData.isCollect = 0
                    this.$toast('已取消~')
                }
            }).catch(err => {
                console.log(err)
            })
        },
        getData() {
            const cityCode = localStorage.getItem("cityCode") || "110100"
            getArtificerDetail({
                "artificerId": this.artificerId,
                "lng": this.lng,
                "lat": this.lat,
                "cityCode":cityCode
            }).then(res => {
                if (res.code == 10000) {
                    this.images = res.data.lsfbArtificerFileEntityList
                    this.infoData = res.data
                    let data = {
                        title: "蓝瑟到家，累了点TA",
                        desc: this.infoData.introduction,
                        link:localStorage.getItem("shareCode")?window.location.href + `&shareCode=${localStorage.getItem("shareCode")}`:window.location.href,
                        imgUrl: this.images[0].fileUrl,
                    }
                    data.link = data.link+'&from=pageShare'
                    wxConfig(data)
                }else{
                    this.$toast('服务出错，请稍后再试');
                }
            }).catch(err => {
                console.log(err)
            })
        },
        getComment(){
            getArtificerDetailComment({
                "artificerId": this.artificerId, 
                "pageIndex": this.page, 
                "pageSize": 10
            }).then(res => {
                if (res.code == 10000) {
                   if(res.data.records.length == 0){
                    this.isLoad = false
                    return
                   }
                   this.commentList = this.commentList.concat(res.data.records)
                   this.commentTotal = res.data.total
                    this.page++;
                }
            }).catch(err => {
                console.log(err)
            })
        },  
        onChange(index) {
            this.current = index;
            // 判断是否为视频，如果是视频，则暂停轮播
            let fileType = this.images[index].fileType
            if (fileType == 0) {
                this.autoplay = 3000
            } else {
                this.autoplay = 0
            }
        },
    }
}
</script>

<style lang="less" scoped>
@import "../../styles/base.less";

.details-box{
    width: 100%;
    height: 100vh;
}

.loading-box{
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .text{
        color: #ccc;
        margin-top: .2rem;
    }
}

.van-image{
    height: 100%;
}



.my-swipe {
    position: relative;

    .custom-indicator {
        position: absolute;
        right: 5px;
        bottom: 5px;
        padding: .3rem .5rem;
        border-radius: .5rem;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        letter-spacing: .05rem;
    }

}

.my-swipe .van-swipe-item {
    color: #fff;
    width: 100%;
    // height: 13rem;
    text-align: center;

    img {
        width: 100%;
        height: 100%;
    }
}



.content {

    .grey-line {
        width: 100%;
        height: .3rem;
        background-color: #EBEBEB;
    }

    .userInfo {
        .paddingBox();

        .user {
            .flex-row-between();

            .user-expos {
                .name-follow {
                    display: flex;
                    align-items: center;

                    .name {
                        font-size: .5rem;
                        font-weight: 600;
                        color: #333;
                    }

                    .follow {
                        font-size: .25rem;
                        background-color: #EB4E52;
                        color: white;
                        // border: solid 1px #F9CACA;
                        border-radius: .1rem;
                        padding: .1rem .2rem;
                        margin-left: .3rem;
                    }
                    .cancelFollow{
                        font-size: .25rem;
                        color: #EB4E52;
                        border: solid 1px #F9CACA;
                        border-radius: .1rem;
                        padding: .1rem .2rem;
                        margin-left: .3rem;
                    }
                }

                .info {
                    margin-top: .4rem;
                    display: flex;
                    align-items: center;

                    .plane {
                        margin: 0 .3rem;
                        color: #ABABAB;

                        img {
                            width: .3rem;
                            height: .3rem;
                        }
                    }

                    .follow_number {
                        color: #ABABAB;
                    }
                }
            }



            .time-show-div {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
            }

            .time-show {
                display: flex;
                flex-direction: column;
                font-size: .28rem;
                color: #EB4E52;
                border: solid 1px #F9CACA;
                border-radius: 3px;

                span {
                    padding: 2px 5px;

                    &:first-child {
                        background: #FBDDDF;
                    }
                }
            }

            .drop {
                display: flex;
                flex-direction: column;
                width: .5rem;
                align-items: center;

                span {
                    width: 3px;
                    height: 3px;
                    background: #000;
                    border-radius: 50%;

                    &:not(:last-child) {
                        margin-bottom: 3px;
                    }
                }
            }

            .popover-div {
                position: absolute;
                right: 0.4rem;
                bottom: 0.2rem;
                background: rgb(228, 223, 223);
                font-size: 0.24rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.18rem .2rem;
                border-radius: .1rem;
                box-shadow: 1px 1px 1px 1px rgb(228, 223, 223);

                .unlikeIcon {
                    font-size: .4rem;
                    margin-right: 0.1rem;
                }

            }
        }

        .notice_lan {
            width: 100%;
            height: 1rem;
            display: flex;
            justify-content: space-around;
            align-items: center;
            background-color: #D9B77A;
            border-radius: .1rem;
            margin: .5rem 0;

            li {
                display: flex;
                align-items: center;

                img {
                    margin-right: .2rem;
                    width: .4rem;
                    height: .4rem;
                }
            }
        }

        .base_info {
            font-size: .35rem;

            .base_box {
                display: grid;
                grid-template-columns: repeat(3, 33.33%);
                grid-gap: .3rem .2rem;
                margin-bottom: .5rem;

                .item {
                    display: flex;

                    .label {
                        white-space: nowrap;
                        color: #A3A3A3;
                    }

                    .text {
                        width: 2rem;
                        // width: 100%;
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

            .desc {
                color: #A3A3A3;
            }
        }
    }

    .project {
        .paddingBox();

        .div-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.3rem;

            .title {
                font-size: 16px;
                font-weight: bold;
                font-family: -apple-system, BlinkMacSystemFont,
                    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
                    "Fira Sans", "Droid Sans", "Helvetica Neue",
                    sans-serif;
            }
        }

        .project-div {
            .projectList {
                display: flex;
                margin-bottom: 20px;

                .projectImg {
                    width: 2.5rem;
                    height: 2.5rem;
                    border-radius: .2rem;
                    margin-right: .4rem;
                }

                .information {
                    flex: 1;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .name {
                        font-size: 16px;
                        font-weight: bold;
                    }

                    .vip-information {
                        display: flex;
                        align-items: center;
                    }

                    .money {
                        font-size: 0.36rem;
                        font-weight: 600;
                        color: orange;
                        width: 1.3rem;

                        .moneyIcon {
                            font-size: .24rem;
                        }
                    }

                    .vipSpan {
                        display: flex;
                        align-items: center;
                        font-size: .28rem;
                        background: rgb(248, 188, 78);
                        border-radius: .4rem;
                        width: 2.7rem;

                        .vipIcon {
                            height: .5rem;
                        }
                    }

                    .timeIcon {
                        font-size: .36rem;
                        margin-right: 3px;
                    }

                    .synopsis-operation {
                        .flex-row-between();
                        align-items: flex-end;

                        .synopsis {
                            flex: 1;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-size: 0.24rem;
                            color: rgb(133, 133, 133);
                        }

                        .operationBtn {
                            width: 1.5rem;
                            margin-left: .5rem;
                        }
                    }
                }
            }
        }
    }

    .commentBox {
        .paddingBox();

        .div-title {
            display: flex;
            justify-content: space-between;
            align-items: center;


            .title {
                font-size: 16px;
                font-weight: bold;
                font-family: -apple-system, BlinkMacSystemFont,
                    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
                    "Fira Sans", "Droid Sans", "Helvetica Neue",
                    sans-serif;
            }
        }

        .comment_item {
            border-bottom: 1px solid #f5f5f5;
            padding: .3rem 0;

            .info_box {
                .flex-row-between();
                align-items: flex-start;

                .user_info {
                    display: flex;
                    align-items: center;

                    .avatar {
                        width: 1.2rem;
                        height: 1.2rem;
                        border-radius: 50%;
                        background-repeat: no-repeat;
                        background-size: cover;
                        margin-right: .2rem;
                    }
                    .info{
                        .name{
                            margin-bottom: .2rem;
                        }
                    }
                }

                .time {
                    color: #959595;
                }
            }

            .comment {
                margin-top: .5rem;
                padding-left: 1.4rem;
            }
        }
    }
}
</style>